import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/Container"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import CtaRightRailAccent from "../components/CtaRightRailAccent"
// import './tutorials.scss'

import client from '../api/contentful'

const Tutorials = () => {
  const[content, setContent] = useState();
  let tutorialsList;
  // console.log(doc)

  useEffect(() => {
    client.getEntries({
        content_type: 'contentPage',
    })
    .then(entry => setContent(entry.items))
    .catch(err => console.log(err));
  }, [])

  const dateOptions = {year: 'numeric', month: 'long', day: 'numeric' };
  if(content) {
    tutorialsList = content.map((item, index)  => {
      let formatDate = Date.parse(item.fields.updated)
      formatDate = new Intl.DateTimeFormat('en-US', dateOptions).format(formatDate)
      return (
          <Link key={index} className="d-block py-4 border-bottom" to={`/tutorials/${item.fields.slug}/`}>
              <h2 className="vertical-listing-title mt-2">{item.fields.title}</h2>
              <p className="mb-1 description">{formatDate} {item.fields.description && <>&mdash; {item.fields.description}</>}</p>
          </Link>
      )
    })
  }
  
  return (
    <Layout>
      <SEO title="Tutorials" />
      {content &&
      <Container>
        <section className="page-content col tutorials">
            <div className="row">
                <Hero title="Tutorials" description="Quick guides to help you work efficiently in UTA's Sitecore CMS"/>

                <div className="col-lg-9 vertical-listing">
                        {tutorialsList}
                </div>

                <div className="col-lg-3">
                    <CtaRightRailAccent headline="Sign up for Training" description="From Sitecore to Cascade 8 to Campus Press, get the right training for you." url="https://webapps.uta.edu/oittraining/" linkText="Upcoming Classes"/>
                </div>
                
            </div>
        </section>
        </Container>}
      
    </Layout>
  )
}

export default Tutorials
